<template>
  <div>
    <div class="content" @click="showtype = false">
      <div class="all" style="position: relative">
        <div class="SearchBar" ref="viewBox">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small">
            <el-form-item label="第三方施工队" class="lab">
              <el-input
                v-model="search.teamName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <div ref="viewContract" style="display: inline-block">
              <el-form-item label="合同类型" class="lab viewContract">
                <el-input
                  @click.native.stop="showtype = true"
                  v-model="search.type"
                  placeholder="请选择"
                  class="SearchInput"
                  clearable
                  @clear="clearType"
                  readonly
                >
                  <template v-slot:suffix @click.stop="clearType">
                    <i
                      @click.stop="clearType"
                      :class="
                        search.type
                          ? 'el-icon-circle-close'
                          : 'el-icon-caret-bottom'
                      "
                    ></i>
                  </template>
                </el-input>
                <div
                  class="searchType"
                  @click.stop="showtype = true"
                  :style="style"
                  v-if="showtype"
                >
                  <div style="margin: 10px">
                    <el-radio-group
                      v-model="search.contractTypeId"
                      @change="changeChildSearch"
                    >
                      <div v-for="item in options" :key="item.id">
                        <el-radio
                          class="radioList"
                          :label="item.id"
                          :key="item.id"
                          >{{ item.name }}</el-radio
                        >
                        <el-checkbox-group
                          @change="changeLabelSearch"
                          v-model="searchContractTypeChildIdArr"
                          style="margin-left: 20px"
                          :disabled="isCheckBokS == 1 ? true : false"
                          v-if="item.children && item.children.length > 0"
                        >
                          <el-checkbox
                            v-for="itemc in item.children"
                            :label="itemc.id"
                            :key="itemc.id"
                            style="width: 140px"
                            >{{ itemc.name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </el-radio-group>
                  </div>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="状态" class="lab">
              <el-select v-model="search.status" clearable placeholder="请选择">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合同编号" class="lab" label-width="95px">
              <el-input
                v-model="search.contractCode"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="lab">
              <el-select
                v-model="search.dateType"
                style="width: 100px; margin-right: 10px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <span>
              <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="getList(1)"
                >查询</el-button
              >
              <el-button
                type="primary"
                plain
                class="SearchBtn2"
                size="small"
                @click="reset"
                >重置</el-button
              >
            </span>
          </el-form>
        </div>
        <div class="table">
          <div>
            <span style="float: right; margin-bottom: 10px">
              <el-button type="primary" @click="showadd" size="small" plain
                >签订</el-button
              ></span
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 432px)"
            :stripe="true"
            @sort-change="sort_change"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractCode"
              label="合同编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="teamName"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractTypeName"
              label="合同类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="签订日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="截至日期"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="annexUrl"
              label="合同附件"
              align="center"
              show-overflow-tooltip
              ><template slot-scope="{ row }">
                <!-- <el-link type="primary" @click="lookpft(row)">查看</el-link> -->
                <a :href="row.annexUrl" download="" target="_back">查看</a>
              </template>
            </el-table-column>
            <el-table-column
              prop="applyCode"
              label="申请单号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="创建人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              show-overflow-tooltip
              sortable="custom"
            ></el-table-column>
            <el-table-column
              prop="annexUrl"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <el-tag
                  v-if="row.status == 1"
                  type="success"
                  size="small"
                  plain
                  >{{ row.statusName }}</el-tag
                >
                <el-tag
                  v-if="row.status == 2"
                  type="primary"
                  size="small"
                  plain
                  >{{ row.statusName }}</el-tag
                >
                <el-tag v-if="row.status == 3" type="info" size="small" plain>{{
                  row.statusName
                }}</el-tag>
                <el-tag
                  v-if="row.status == 4"
                  type="warning"
                  size="small"
                  plain
                  >{{ row.statusName }}</el-tag
                >
                <el-tag
                  v-if="row.status == 5"
                  type="danger"
                  size="small"
                  plain
                  >{{ row.statusName }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="300px"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="desc(row)"
                  >查看</el-button
                >
                <el-button
                  v-if="row.status == 1 && row.isApply == 1"
                  type="danger"
                  size="small"
                  @click="apply(row, 'del')"
                  >申请作废</el-button
                >
                <el-button
                  v-if="row.status == 1 && row.isApply == 1"
                  type="warning"
                  size="small"
                  @click="apply(row, 'stop')"
                  >申请终止</el-button
                >
                <el-button
                  v-if="row.status == 4 || row.status == 2"
                  type="info"
                  size="small"
                  @click="apply(row, 'new')"
                  >撤销</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="合同签订"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        ref="addform"
        :model="form"
        label-width="130px"
        :rules="rule"
        size="small"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="资质申请" prop="applyId">
                <el-select
                  v-model="form.applyId"
                  placeholder="请选择"
                  filterable
                  value-key="id"
                  @change="getTeamList"
                  style="width: 173px"
                >
                  <el-option
                    v-for="item in teamList"
                    :key="item.id"
                    :label="item.applyCode + '_' + item.teamName"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="施工队">
                <el-input
                  v-model="teamDsc.teamName"
                  placeholder="请选择资质申请"
                  disabled
                  name="limitLength"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="签订合同类型">
                <el-input
                  v-model="teamDsc.contractTypeName"
                  placeholder="请选择资质申请"
                  disabled
                  name="limitLength"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="合同编号" prop="contractCode">
                <el-input
                  v-model="form.contractCode"
                  placeholder="请输入合同编号"
                  name="limitLength"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="签订日期" prop="startDate">
                <el-date-picker
                  v-model="form.startDate"
                  type="date"
                  style="width: 173px"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="截至日期" prop="endDate">
                <el-date-picker
                  v-model="form.endDate"
                  type="date"
                  style="width: 173px"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="附件说明" prop="remark">
                <el-input
                  type="text"
                  v-model="form.remark"
                  name="limitLength"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="合同附件" prop="urlList">
                <span slot="label">
                  合同附件<span style="color: red; margin-left: 4px">*</span>
                </span>
                <el-upload
                  ref="upload"
                  action="#"
                  :auto-upload="true"
                  :http-request="repairBeforUploadFun"
                  list-type="fileList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :before-upload="beforUploadImg"
                  :on-change="beforHFhandleChangeImg"
                  :file-list="repairBeforFilefileList"
                  accept=".pdf"
                  :limit="1"
                  :on-exceed="msgLimit"
                >
                  <el-button size="small" type="text">上传PDF附件</el-button>
                  <!-- <div slot="file" slot-scope="{file}" >
                <span  @click="lookptf(file.url)"><a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{file.name}}</a></span>
                <label class="el-upload-list__item-status-label"><i class="el-icon-upload-success"></i></label>
                <i class="el-icon-close" @click="handleRemove(file)"></i><i class="el-icon-close-tip">按 delete 键可删除</i>
                </div> -->
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right: 10px" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="applyName + '申请'"
      :visible.sync="dialogVisibleApply"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        ref="Applyform"
        :model="form"
        label-width="120px"
        size="mini"
        :rules="rulesApp"
      >
        <div>
          <el-form-item :label="applyName + '原因：'" prop="positionName">
            <el-input
              type="textarea"
              v-model="form.reason"
              :autosize="{ minRows: 2, maxRows: 4 }"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item
            label="终止日期："
            prop="positionCode"
            v-if="applyflag == 'stop'"
          >
            <el-date-picker
              v-model="form.stopDate"
              type="date"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="margin-right: 10px"
          @click="dialogVisibleApply = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveApply">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="合同签订"
      :visible.sync="dialogVisibleDesc"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <div>
          <el-row>
            <el-col :span="10">
              <el-form-item label="资质申请：" prop="positionName">
                {{ form.applyCode }}
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="施工队：" prop="positionCode">
                {{ form.teamName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="签订合同类型:" prop="positionCode">
                {{ form.contractTypeName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="签订日期:" prop="positionCode">
                <span style="word-break: break-all">{{ form.startDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="截至日期:">
                <span style="word-break: break-all">{{ form.endDate }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="原截至日期:" v-if="form.endDateOld">
                <span style="word-break: break-all">{{ form.endDateOld }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="创建人:">
                <span style="word-break: break-all">{{ form.createBy }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="创建时间:">
                <span style="word-break: break-all">{{ form.createTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="合同附件:">
                <a :href="form.annexUrl" download="" target="_back"
                  >附件合同.ptf</a
                >
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="作废记录" name="first">
            <el-table
              :data="form.revokeList"
              style="width: 100%; margin: 15px 0"
              border
              :max-height="450"
              :stripe="true"
            >
              <el-table-column
                prop="typeCodeName"
                label="操作类型"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="createBy"
                label="操作人"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="操作时间"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="reason"
                label="申请原因"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="终止记录" name="second">
            <el-table
              ref="tableData"
              :data="form.stopList"
              style="width: 100%; margin: 15px 0"
              border
              :max-height="450"
              :stripe="true"
            >
              <el-table-column
                prop="typeCodeName"
                label="操作类型"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="createBy"
                label="操作人"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="操作时间"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="reason"
                label="申请原因"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="stopDate"
                label="终止日期"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="margin-right:10px" @click="dialogVisibleDesc = false">取消</el-button> -->
      </div>
    </el-dialog>
    <conPtf ref="ptfshow"></conPtf>
  </div>
</template>
<script>
import {
  contractStatus,
  getDate,
  contractOrder_page,
  apply_applyCodeDic,
  contractOrder_save,
  contractOrder_revoke,
  contractOrder_stop,
  contractOrder_cancel,
  contractOrder_findById,
} from "../../RequestPort/apply/contract.js";
import { uploadImg } from "../../RequestPort/apply/apply.js";
import { selectDictListByParentCode } from "@/apis/commonType";
import conPtf from "./conPtf.vue";
export default {
  components: { conPtf },
  name: "conntract",
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100, type: "", dateType: "1" }, //搜索
      searchContractTypeChildIdArr: [],
      searchDatatime: [],
      total: 10,
      tableData: [], //列表对象
      options: [],
      statusList: contractStatus(),
      dialogVisible: false,
      form: { urlList: [], startDate: getDate() },
      rule: {
        contractCode: {
          required: true,
          message: "请选择合同编号",
          trigger: "blur",
        },
        startDate: {
          required: true,
          message: "请选择签订日期",
          trigger: "blur",
        },
        endDate: { required: true, message: "请选择截至日期", trigger: "blur" },
        //  urlList:{ required: true, message: "请上传附件合同", trigger: "change" },
      },
      rulesApp: {},
      dialogVisibleDesc: false,
      isCheckBokS: true,
      parentName: "",
      showtype: false,
      teamList: [],
      repairBeforFileFormData: new FormData(),
      repairBeforFilefileList: [],
      isturn: 0,
      source: 1,
      dataList: [
        { label: "签订日期", value: "1" },
        { label: "截止日期", value: "2" },
      ],
      teamDsc: {},
      disabled: false,
      dialogVisibleApply: false,
      applyName: "",
      pickerOptions: {},
      applyflag: "",
      activeName: "first",
      dom: null,
      style: {
        left: 0,
      },
      left: 0,
    };
  },
  mounted() {
    this.getOption();
    this.getList();
    this.left = this.$refs.viewContract.offsetLeft + 90;
    this.style.left = this.left + "px";
    this.dom = this.$refs.viewBox;
    this.dom.addEventListener("scroll", this.handleFun);
  },
  beforeDestroy() {
    if (this.dom) this.dom.removeEventListener("scroll", this.handleFun);
  },
  methods: {
    handleFun() {
      this.style.left = this.left - this.dom.scrollLeft + "px";
    },
    getOption() {
      selectDictListByParentCode({ parentCode: "hetong", level: 2 }).then(
        (res) => {
          if (res.code == 200) {
            this.options = res.data;
          }
        }
      );
    },
    sort_change(column) {
      this.search.sortColumn = column.prop;
      if (column.order == "ascending") {
        this.search.sortKey = 0;
      } else {
        this.search.sortKey = 1;
      }
      this.getList(1);
    },
    clearType() {
      if (this.search.type) {
        this.search.contractTypeId = "";
        this.searchContractTypeChildIdArr = [];
        this.search.contractTypeChildId = "";
        this.isCheckBokS = true;
        this.search.type = "";
        this.showtype = false;
      }
    },
    lookpft(row) {
      this.$refs.ptfshow.previewPDF(row.annexUrl);
    },
    changeChildSearch(val) {
      this.options.forEach((el) => {
        if (el.id == val) {
          if (el.children) {
            this.isCheckBokS = false;
          } else {
            this.isCheckBokS = true;
            this.searchContractTypeChildIdArr = [];
          }
          this.search.type = el.name;
          this.parentName = el.name;
        }
      });
    },
    changeLabelSearch(val) {
      var namekey = {};
      this.options.forEach((el) => {
        if (el.id == this.search.contractTypeId) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });
          let showname = name.toString();
          if (showname) {
            this.search.type = this.parentName + "(" + showname + ")";
          }
        }
      });
    },
    getTeamList(item) {
      this.teamDsc = item;
    },
    repairBeforUploadFun(e) {
      this.repairBeforFileFormData.append("file", e.file);
      uploadImg(this.repairBeforFileFormData).then((res) => {
        if (res.code == 200) {
          this.form.urlList.push(res.data.url);
        }

        this.repairBeforFileFormData.delete("file");
      });
    },
    beforUploadImg(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["pdf"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是pdf");
        return false;
      }
      const size = file.size / 1024 / 1024;
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    msgLimit(file, fileList) {
      this.$message.error("只能上传一个pdf");
    },
    beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList;
    },
    handleRemove(file) {
      this.repairBeforFilefileList = [];
      this.form.urlList = [];
    },
    handlePictureCardPreview(file) {
      this.$refs.ptfshow.previewPDF(file.url);
    },
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      this.search.contractTypeChildId = "";
      if (this.searchContractTypeChildIdArr.length > 0) {
        this.search.contractTypeChildId =
          this.searchContractTypeChildIdArr.toString();
      }
      if (current) {
        this.search.current = 1;
      }
      //查列表
      contractOrder_page(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },

    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
        dateType: "1",
      }),
        (this.searchContractTypeChildIdArr = []);
      this.searchDatatime = [];
      this.getList(1);
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    showadd() {
      this.form.startDate = getDate();
      this.getTeam();
      this.dialogVisible = true;
    },
    getTeam() {
      apply_applyCodeDic({}).then((res) => {
        if (res.code == 200) {
          this.teamList = res.data;
        }
      });
    },
    clearnF() {
      this.form = {
        urlList: [],
        startDate: getDate(),
        applyId: "",
        contractCode: "",
        endDate: "",
        remark: "",
        startDate: "",
        endDate: "",
        reason: "",
        stopList: [],
        revokeList: [],
      };
      this.teamDsc = {};
      this.activeName = "first";

      this.repairBeforFilefileList = [];
      if (this.$refs["addform"]) {
        this.$refs["addform"].resetFields();
      }

      //  this.$refs['Applyform'].resetFields();
    },
    saveadd() {
      this.$refs["addform"].validate((valid) => {
        if (valid) {
          if (this.form.urlList.length <= 0) {
            this.$message.error("请上传附件合同");
            return;
          }
          var data = {
            contractCode: this.form.contractCode,
            annexUrl: this.form.urlList[0],
            endDate: this.form.endDate,
            remark: this.form.remark,
            startDate: this.form.startDate,
            id: 0,
          };
          if (this.form.applyId) {
            data.applyId = this.form.applyId.id;
          }
          contractOrder_save(data).then((res) => {
            if (res.code == "200") {
              this.$message.success("添加成功");
              this.clearnF();
              this.getList(1);
              this.dialogVisible = false;
            }
          });
        }
      });
    },
    desc(row) {
      contractOrder_findById({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.form = res.data;
          this.dialogVisibleDesc = true;
        }
      });
    },
    handleClick() {},

    apply(row, flag) {
      this.form.id = row.id;
      this.applyflag = flag;
      if (flag == "del") {
        this.applyName = "作废";
      } else if (flag == "stop") {
        this.applyName = "终止";
        this.pickerOptions = {
          disabledDate: (time) => {
            let d = new Date(row.endDate);
            let curDate = new Date();
            var preDate = new Date(curDate.getTime() - 24 * 60 * 60 * 1000);
            return time.getTime() <= preDate || time.getTime() > d.getTime();
          },
        };
      } else {
        this.applyName = "撤回";
      }

      this.dialogVisibleApply = true;
    },
    saveApply() {
      this.$refs["Applyform"].validate((valid) => {
        if (valid) {
          var data = { reason: this.form.reason, orderId: this.form.id };
          if (this.applyflag == "del") {
            contractOrder_revoke(data).then((res) => {
              if (res.code == "200") {
                this.$message.success("申请" + this.applyName + "成功");
                this.clearnF();
                this.getList(1);
                this.dialogVisibleApply = false;
              }
            });
          } else if (this.applyflag == "stop") {
            data.stopDate = this.form.stopDate;
            contractOrder_stop(data).then((res) => {
              if (res.code == "200") {
                this.$message.success("申请" + this.applyName + "成功");
                this.clearnF();
                this.getList(1);
                this.dialogVisibleApply = false;
              }
            });
          } else {
            contractOrder_cancel(data).then((res) => {
              if (res.code == "200") {
                this.$message.success("申请" + this.applyName + "成功");
                this.clearnF();
                this.getList(1);
                this.dialogVisibleApply = false;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.all {
  background-color: #f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 20px;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
::v-deep.viewContract .el-form-item__content {
  position: initial;
}
</style>
