import axios from '@/common/js/request'

export function contractOrder_page(data){
    const contractOrder_page = axios({
        url: '/contractOrder/page',
        method: 'get',
        params:data
    })
    return contractOrder_page
}
export function contractStatus(){
    const list = [
        {value:1,label:'正常'},
        {value:2,label:'待作废'},
        {value:3,label:'已作废'},
        {value:4,label:'待终止'},
        {value:5,label:'已终止'},
    ]
    return list;
}

export function apply_applyCodeDic(data){
    const apply_applyCodeDic = axios({
        url: '/apply/applyCodeDic',
        method: 'get',
        params:data
    })
    return apply_applyCodeDic
}
export function contractOrder_save(data){
    const contractOrder_save = axios({
        url: '/contractOrder/save',
        method: 'post',
        data:data
    })
    return contractOrder_save
}
export function contractOrder_revoke(data){
    const contractOrder_revoke = axios({
        url: '/contractOrder/revoke',
        method: 'post',
        data:data
    })
    return contractOrder_revoke
}
export function contractOrder_stop(data){
    const contractOrder_stop = axios({
        url: '/contractOrder/stop',
        method: 'post',
        data:data
    })
    return contractOrder_stop
}
export function contractOrder_cancel(data){
    const contractOrder_cancel = axios({
        url: '/contractOrder/cancel',
        method: 'post',
        data:data
    })
    return contractOrder_cancel
}
export function contractOrder_findById(data){
    const contractOrder_findById = axios({
        url: '/contractOrder/findById',
        method: 'get',
        params:data
    })
    return contractOrder_findById
}
export function getDate() {
    var myDate = new Date();
    var month = parseInt(myDate.getMonth())+1;
    month = (Array(2).join(0) + month).slice(-2)
    var  day = myDate.getDate();
    day = (Array(2).join(0) + day).slice(-2)
    var date = myDate.getFullYear()+"-"+month+"-"+day;
    return date;
  }